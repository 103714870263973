import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PAYPERIOD,
  GET_PAYPERIOD_LOADING,
  GET_PAYPERIOD_SUCCESS,
  GET_PAYPERIOD_ERROR,
  CREATE_PAYPERIOD,
  CREATE_PAYPERIOD_LOADING,
  CREATE_PAYPERIOD_SUCCESS,
  CREATE_PAYPERIOD_ERROR,
  DELETE_PAYPERIOD,
  DELETE_PAYPERIOD_LOADING,
  DELETE_PAYPERIOD_SUCCESS,
  DELETE_PAYPERIOD_ERROR,
  CLOSE_PAYPERIOD,
  CLOSE_PAYPERIOD_LOADING,
  CLOSE_PAYPERIOD_SUCCESS,
  CLOSE_PAYPERIOD_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';
import { workingTypes } from './constants';

function* getPayPeriod({ body }) {
  yield put({ type: GET_PAYPERIOD_LOADING });
  try {
    const query = {
      page_number: body.page_number,
      items_per_page: 10,
      employer_id: body.employerId,
      not_closed: body.not_closed,
    };
    const url = apiConfig.payPeriod.get_payperiods(query);
    const { data } = yield call(api, {
      method: 'GET',
      url,
    });

    yield put({ type: GET_PAYPERIOD_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PAYPERIOD_ERROR, errorMessage: '' });
  }
}

function* createPayPeriod({ body }) {
  yield put({ type: CREATE_PAYPERIOD_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.payPeriod.create_payperiod({ employerId: body.employerId }),
      body: {
        name: body.name,
        from: body.from,
        to: body.to,
        lockingDate: body.lockingDate ? body.lockingDate : body.to,
        payDate: body.payDate,
        workingType: body.workingType ? body.workingType : workingTypes[0].value,
      },
    });
    yield put({ type: CREATE_PAYPERIOD_SUCCESS, data: data });
    yield put({ type: GET_PAYPERIOD, body: { employerId: body.employerId } });
  } catch (error) {
    console.log(error);
    errorhandling(error);
    yield put({ type: CREATE_PAYPERIOD_ERROR, errorMessage: '' });
  }
}

function* deletePayPeriod({ body }) {
  yield put({ type: DELETE_PAYPERIOD_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'DELETE',
      url: apiConfig.payPeriod.delete_payperiod({
        employerId: body.employerId,
        payPeriodId: body.id,
      }),
      body,
    });
    yield put({ type: DELETE_PAYPERIOD_SUCCESS, data: data });
    yield put({ type: GET_PAYPERIOD, body: { employerId: body.employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: DELETE_PAYPERIOD_ERROR, errorMessage: '' });
  }
}

function* closePayPeriod({ body }) {
  yield put({ type: CLOSE_PAYPERIOD_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.payPeriod.close_payperiod({
        employerId: body.employerId,
        payPeriodId: body.id,
      }),
      body,
    });
    yield put({ type: CLOSE_PAYPERIOD_SUCCESS, data: data });
    yield put({ type: GET_PAYPERIOD, body: { employerId: body.employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: CLOSE_PAYPERIOD_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionsSaga() {
  yield takeLatest(GET_PAYPERIOD, getPayPeriod);
  yield takeLatest(CREATE_PAYPERIOD, createPayPeriod);
  yield takeLatest(DELETE_PAYPERIOD, deletePayPeriod);
  yield takeLatest(CLOSE_PAYPERIOD, closePayPeriod);
}

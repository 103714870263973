import React, { useEffect } from 'react';
import { Button, Col, Form, Layout, Row } from 'antd';
import { useDispatch } from 'react-redux';
import BreadcrumbList from '../../../shared/components/Breadcrumb';
import LogoUploadSection from './UploadLogoSection/UploadLogoSection';
import { updateEmployerThemeColorScheme } from '../actions';
import { ColorSchemeSection, SizesSection, ToggleThemeSection } from './index';
import { breadcrumbListItems, defaultTheme } from '../constants';
import { merge } from 'lodash';
import { isColorTransparent } from '../../../utils/formatString';

export default function EmployerThemeComponent({ theme, selectedEmployer, loading }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // Update form values when theme changes
  useEffect(() => {
    // Merge theme with default to override null values.
    form.setFieldsValue(merge({}, defaultTheme, theme));
  }, [theme]);

  // update color scheme values by removing transparent colors
  const updateColorSchemeValues = ({ background, ...rest }) => {
    // Filter out transparent colors from background
    const cleanBackground = Object.entries(background)
      .filter(([_, color]) => !isColorTransparent(color))
      .reduce((acc, [key, color]) => ({ ...acc, [key]: color }), {});

    // Filter out transparent colors from other properties
    const cleanColorScheme = Object.entries(rest)
      .filter(([_, color]) => !isColorTransparent(color))
      .reduce((acc, [key, color]) => ({ ...acc, [key]: color }), {});

    return {
      ...cleanColorScheme,
      background: { ...cleanBackground },
    };
  };

  const onSave = (values) => {
    const payload = {
      theme: {
        ...values,
        colorScheme: updateColorSchemeValues(values.colorScheme),
      },
      employerId: selectedEmployer.value,
    };
    dispatch(updateEmployerThemeColorScheme(payload));
  };

  const onResetColorScheme = () => {
    form.setFieldsValue(defaultTheme);
    dispatch(
      updateEmployerThemeColorScheme({ theme: defaultTheme, employerId: selectedEmployer.value })
    );
  };

  // if no employer is selected show empty layout
  if (!selectedEmployer?.value) {
    return (
      <Layout>
        <BreadcrumbList items={breadcrumbListItems} />
      </Layout>
    );
  }

  return (
    <Layout>
      <BreadcrumbList items={breadcrumbListItems} />
      <Form
        form={form}
        layout="vertical"
        initialValues={merge({}, defaultTheme, theme)}
        onFinish={onSave}>
        <Row gutter={[16, 16]}>
          {/* Theme enable/disable toggle */}
          <ToggleThemeSection />

          <Col span={24}>
            <LogoUploadSection theme={theme} selectedEmployer={selectedEmployer} />
          </Col>

          {/* Theme color scheme */}
          <Col span={24}>
            <ColorSchemeSection
              onReset={onResetColorScheme}
              theme={theme}
              selectedEmployer={selectedEmployer}
              form={form}
            />
          </Col>
          <Col span={24}>
            <SizesSection />
          </Col>

          <Col span={6} offset={16}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Save Theme Settings
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
}

import { all, fork } from 'redux-saga/effects';
import watchSharedSaga from '../shared/saga';
import watchLoginSaga from '../screens/login/saga';
import watchEmployeeDetailsSaga from '../screens/employeeDetails/saga';
import watchEmployerDetailsSaga from '../screens/employerDetails/saga';
import watchEmployeesSaga from '../screens/employees/saga';
import watchEmployerDashboardSaga from '../screens/employerDashboard/saga';
import watchTransactionsSaga from '../screens/transactions/saga';
import watchInvoicesSaga from '../screens/invoices/saga';
import watchInvoiceDetailsSaga from '../screens/invoiceDetails/saga';
import watchSuperAdminDashboardSaga from '../screens/superAdminDashboard/saga';
import watchPromotionsSaga from '../screens/promotions/saga';
import watchPromotionDetailsSaga from '../screens/promotionDetails/saga';
import watchEmployerSaga from '../screens/employers/saga';
import watchPayCyclesSaga from '../screens/payCycles/saga';
import watchStartPayPeriodSaga from '../screens/startPayPeriod/saga';
import watchUsersSaga from '../screens/users/saga';
import watchChangePasswordSaga from '../screens/changePassword/saga';
import watchDeductionSummarySaga from '../screens/deductionSummary/saga';
import watchPayrollDataSaga from '../screens/payrollData/saga';
import watchEmployeeEligibilitySaga from '../screens/employeeEligibility/saga';
import watchForgotPasswordSaga from '../screens/forgotPassword/saga';
import watchInviteSaga from '../screens/invitation/saga';
import watchPayPeriodsSaga from '../screens/payPeriods/saga';
import watchAdvanceDetails from '../screens/advanceDetails/saga';
import watchPayrollDataDetails from '../screens/payrollDataDetails/saga';
import watchEmployerThemeSaga from '../screens/employerTheme/saga';
import watchEmployerReminderSaga from "../screens/employerReminder/saga";

// new
import watchSettingsSaga from '../screens/settings/saga';
import watchMFASaga from '../screens/MFA/saga';
export default function* root() {
  yield all([
    fork(watchSharedSaga),
    fork(watchLoginSaga),
    fork(watchEmployeeDetailsSaga),
    fork(watchEmployerDetailsSaga),
    fork(watchEmployeesSaga),
    fork(watchEmployerDashboardSaga),
    fork(watchTransactionsSaga),
    fork(watchInvoicesSaga),
    fork(watchInvoiceDetailsSaga),
    fork(watchPromotionsSaga),
    fork(watchPromotionDetailsSaga),
    fork(watchSuperAdminDashboardSaga),
    fork(watchEmployerSaga),
    fork(watchPayCyclesSaga),
    fork(watchStartPayPeriodSaga),
    fork(watchUsersSaga),
    fork(watchChangePasswordSaga),
    fork(watchDeductionSummarySaga),
    fork(watchPayrollDataSaga),
    fork(watchEmployeeEligibilitySaga),
    fork(watchForgotPasswordSaga),
    fork(watchInviteSaga),
    fork(watchPayPeriodsSaga),
    fork(watchAdvanceDetails),
    fork(watchPayrollDataDetails),

    // new
    fork(watchSettingsSaga),
    fork(watchMFASaga),
    fork(watchEmployerThemeSaga),
    fork(watchEmployerReminderSaga)
  ]);
}

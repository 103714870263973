import React, { useMemo } from 'react';
import { Layout, Menu, Skeleton, theme } from 'antd';
import { useSelector } from 'react-redux';
import '../../style.css';
import { LoginMin, Logo } from '../../../assets/images';
import { CustomImage } from '../..';
import { employerThemeSelector } from '../../../screens/employerTheme/actions';

const { Sider } = Layout;
const { useToken } = theme;

const LogoSkeleton = ({ isSidebarCollapsed }) => {
  const width = isSidebarCollapsed ? 45 : 225;
  return <Skeleton.Node active={true} style={{ width, height: 45 }} />;
};

const Sidebar = ({ isSidebarCollapsed, navigateTo, path, menu, userDetails }) => {
  const { token } = useToken();
  // retrieves the employer theme from the store
  const { loading, data: theme } = useSelector(employerThemeSelector);

  // logo selection along with the URLs
  const logo = useMemo(() => {
    if (loading) return; // Exit if loading

    const defaultLogo = isSidebarCollapsed ? LoginMin : Logo;

    // default logo if theme is disabled or logo config missing
    if (!theme?.isEnabled || !theme?.logo) return defaultLogo;

    const { iconVersionUrl, fullVersionUrl } = theme.logo;
    // pick URL based on sidebar
    const selectedUrl = isSidebarCollapsed ? iconVersionUrl : fullVersionUrl;

    // return selected URL if available, otherwise fallback to default
    return selectedUrl || defaultLogo;
  }, [isSidebarCollapsed, theme?.isEnabled, theme?.logo, loading]);

  const menuItems = menu
    .map((item) => {
      if (!item.roles || item.roles.includes(userDetails['custom:role'])) {
        return {
          key: item.path,
          icon: item.icon,
          label: item.label,
          disabled: item.disabled,
        };
      }
      return null;
    })
    .filter(Boolean); // Filter out null values

  return (
    <Sider
      width={260}
      trigger={null}
      collapsible
      collapsed={isSidebarCollapsed}
      breakpoint="lg"
      className="container"
      style={{
        position: "sticky",
        top: 0,
        overflow: "auto",
        maxHeight: "100vh",
      }}
    >
      <div className="logo">
        {loading ? (
          <LogoSkeleton isSidebarCollapsed={isSidebarCollapsed} />
        ) : (
          <CustomImage src={logo} placeholder={true} />
        )}
      </div>
      <Menu
        mode="inline"
        selectedKeys={[path]}
        onClick={({ key }) => navigateTo(key)}
        items={menuItems}
        style={{ backgroundColor: token?.Layout?.siderBg }}
      />
      {/*<Menu mode="inline" selectedKeys={[path]} onClick={({item, key, keyPath, domEvent}) => navigateTo(key)}>
                {menu.map((item, index) => {

                        if (!item.roles) {
                            return <Menu.Item key={item.path} icon={item.icon} disabled={item.disabled}>
                                {item.label}
                            </Menu.Item>
                        } else if (item.roles.includes(userDetails["custom:role"])) {
                            return <Menu.Item key={item.path} icon={item.icon} disabled={item.disabled}>
                                {item.label}
                            </Menu.Item>
                        } else {
                            return null
                        }

                    }
                )}
            </Menu>*/}
    </Sider>
  );
};

export default Sidebar;
